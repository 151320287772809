import { useMobileContext } from '@/MobileContext'
import clsx from 'clsx'
import React, { useCallback, useState } from 'react'
import styled from 'styled-components'

const StyledCursor = styled.div`
  .main-cursor,
  .secondary-cursor {
    z-index: 10000;
    pointer-events: none;
    position: fixed;
    transform: translate3d(0, 0, 0);
  }

  .main-cursor {
    transition: opacity, scale 1s cubic-bezier(0.77, 0, 0.175, 1);
    animation: fadeIn 1s cubic-bezier(0.77, 0, 0.175, 1) 0s forwards;
    mix-blend-mode: difference;

    .main-cursor-background {
      width: 30px;
      height: 30px;
      transform: scale(${props => props.scale});
      transition: 300ms;
      background: white;
      border-radius: 50%;
    }
  }

  .secondary-cursor {
    opacity: ${props => (props.scale === 1 ? 1 : 0)};
    mix-blend-mode: difference;

    .cursor-background {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      border: 1px solid white;
      position: relative;
    }
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
`

export const Cursor = ({ scale, customCursor, hideCursor }) => {
  const { mobile } = useMobileContext()
  const [displayCursor, setDisplayCursor] = useState(false)
  const secondaryCursor = React.useRef(null)
  const mainCursor = React.useRef(null)
  const positionRef = React.useRef({
    mouseX: 0,
    mouseY: 0,
    destinationX: 0,
    destinationY: 0,
    distanceX: 0,
    distanceY: 0,
    key: -1,
  })

  const onMouseEnter = useCallback(
    event => {
      setDisplayCursor(true)
      if (!mainCursor.current || !secondaryCursor.current) return
      const { clientX, clientY } = event

      const mouseX = clientX
      const mouseY = clientY

      positionRef.current.mouseX =
        mouseX - secondaryCursor.current?.clientWidth / 2
      positionRef.current.mouseY =
        mouseY - secondaryCursor.current?.clientHeight / 2

      if (mainCursor.current) {
        mainCursor.current.style.transform = `translate3d(${
          mouseX - mainCursor.current?.clientWidth / 2
        }px, ${mouseY - mainCursor.current?.clientHeight / 2}px, 0)`
      }
    },
    [mainCursor, secondaryCursor],
  )

  React.useEffect(() => {
    document.addEventListener('mousemove', onMouseEnter)
    if (mobile) {
      document.removeEventListener('mousemove', onMouseEnter)
    }
    return () => {
      document.removeEventListener('mousemove', onMouseEnter)
    }
  }, [mobile, onMouseEnter])

  React.useEffect(() => {
    if (!secondaryCursor.current || mobile) return
    const followMouse = () => {
      positionRef.current.key = requestAnimationFrame(followMouse)
      const {
        mouseX,
        mouseY,
        destinationX,
        destinationY,
        distanceX,
        distanceY,
      } = positionRef.current
      if (!destinationX || !destinationY) {
        positionRef.current.destinationX = mouseX
        positionRef.current.destinationY = mouseY
      } else {
        positionRef.current.distanceX = (mouseX - destinationX) * 0.1
        positionRef.current.distanceY = (mouseY - destinationY) * 0.1
        if (
          Math.abs(positionRef.current?.distanceX) +
            Math.abs(positionRef.current?.distanceY) <
          0.1
        ) {
          positionRef.current.destinationX = mouseX
          positionRef.current.destinationY = mouseY
        } else {
          positionRef.current.destinationX += distanceX
          positionRef.current.destinationY += distanceY
        }
      }

      if (secondaryCursor.current && mainCursor.current) {
        secondaryCursor.current.style.transform = `translate3d(${destinationX}px, ${destinationY}px, 0)`
      }
    }
    followMouse()
  }, [mobile, secondaryCursor, mainCursor])

  const CustomComponent = customCursor?.component
  const customProps = customCursor?.props || {}

  return (
    <StyledCursor
      className={
        displayCursor && !hideCursor && !mobile ? 'opacity-100' : 'opacity-0'
      }
      scale={scale}
    >
      <div className="cursor-wrapper">
        <div className="main-cursor" ref={mainCursor}>
          <div
            className={clsx(
              'main-cursor-background',
              CustomComponent && 'opacity-0',
            )}
          />
        </div>
        {CustomComponent ? (
          <CustomComponent secondaryCursor={secondaryCursor} {...customProps} />
        ) : (
          <div className="secondary-cursor w-16 h-16" ref={secondaryCursor}>
            <div className="cursor-background" />
          </div>
        )}
      </div>
    </StyledCursor>
  )
}
