import React, { memo, useMemo, useRef } from 'react'
import styled from 'styled-components'
// import Switch from './Controls/Switch'
import clsx from 'clsx'
import { useHomeContext } from '@/Containers/Home/HomeContext'
import { Interactive } from './Cursor/Interactive'
import { useControlScrollContext } from './ControlScrollProvider'
import { LanguageSwitch } from './LanguageSwitch'
import { useTranslation } from 'react-i18next'
import { Link, useLocation } from 'react-router-dom'
import { useMobileContext } from '@/MobileContext'

const Navbar = styled.nav`
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  height: 64px;

  * {
    font-family: 'Satoshi Variable', sans-serif;
  }

  @media (max-width: 640px) {
    height: 48px;
  }
`

const NavList = styled.ul`
  list-style: none;
  display: flex;
  gap: 48px;
  margin: 0;
  padding: 0;
  flex: 1;
  justify-content: center;
`

const NavItem = styled.li`
  font-size: 18px;
  color: ${props => props.theme.colors.black};
  font-weight: 500;
`

const NavLink = styled(Link)`
  font-size: 18px;
  color: ${props => props.theme.colors.black};
  font-weight: 500;
`

const ContactButton = styled(Link)`
  background-color: ${props => props.theme.colors.black};
  color: ${props => props.theme.colors.white};
  height: 54px;
  padding: 0px 20px;
  min-width: 160px;
  border-radius: 48px;
  font-size: 16px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 640px) {
    min-width: 120px;
    height: 48px;
    font-size: 14px;
  }
`

const Nav = () => {
  const { t } = useTranslation()
  const { windowHeight } = useHomeContext()
  const { mobile } = useMobileContext()
  const { onScrollTo } = useControlScrollContext()
  const items = useMemo(
    () => [
      {
        label: t('header.nav_item_1'),
        top: windowHeight * 2.38,
      },
      {
        label: t('header.nav_item_2'),
        top: windowHeight * 15.36,
      },
      {
        label: t('header.nav_item_3'),
        top: windowHeight * 23.1,
      },
      {
        label: t('header.nav_item_4'),
        top: windowHeight * 26.6,
      },
    ],
    [windowHeight, t],
  )

  const home = useLocation().pathname === '/'

  if (home && mobile) {
    return <NavList />
  }

  if (!home) {
    return (
      <NavList>
        <li>
          <NavLink to="/">
            <Interactive className="max-sm:hidden">
              {t('header.back')}
            </Interactive>
          </NavLink>
        </li>
      </NavList>
    )
  }

  return (
    <NavList>
      {items.map((item, index) => (
        <NavItem onClick={() => onScrollTo(item.top)}>
          <Interactive key={index}>{item.label}</Interactive>
        </NavItem>
      ))}
    </NavList>
  )
}

const StyledHeader = styled.header`
  will-change: margin-top, position;
`

const Header = memo(() => {
  const headerRef = useRef(null)
  const { t } = useTranslation()

  return (
    <StyledHeader
      ref={headerRef}
      className={clsx(
        ' w-screen z-10 py-10 px-32 flex justify-center max-xl:px-16 max-md:px-2 max-md:py-4',
      )}
    >
      <Navbar>
        <Link className="h-full" to="/">
          <img className="h-full" src="/logo.png" alt="Icon" loading="lazy" />
        </Link>
        <Nav />
        <div className="flex items-center gap-4 max-sm:gap-2">
          {/* <Interactive>
            <Switch />
          </Interactive> */}
          <LanguageSwitch />
          <Interactive>
            <ContactButton to="/contact">
              {t('header.contact_us')}
            </ContactButton>
          </Interactive>
        </div>
      </Navbar>
    </StyledHeader>
  )
})

export default Header
