import { useMobileContext } from '@/MobileContext'
import React, { useRef, useState } from 'react'
import styled from 'styled-components'

const ButtonWrapper = styled.div`
  position: relative;
  background-color: ${props => props.theme.colors.primary};
  border-radius: 999px;
  transition: transform 0.2s ease-out;
`

const ButtonPosition = ({ children, disabled }) => {
  const { mobile } = useMobileContext()
  const [position, setPosition] = useState({ x: 0, y: 0 })
  const divRef = useRef(null)
  const handleMouseMove = event => {
    if (divRef.current) {
      const rect = divRef.current.getBoundingClientRect()
      const x = ((event.clientX - rect.left) / rect.width - 0.5) * 50
      const y = ((event.clientY - rect.top) / rect.height - 0.5) * 50
      setPosition({ x, y })
    }
  }

  const resetPosition = () => {
    setPosition({ x: 0, y: 0 })
  }

  return (
    <ButtonWrapper
      style={
        !mobile && !disabled
          ? { transform: `translate(${position.x}px, ${position.y}px)` }
          : null
      }
      onMouseMove={handleMouseMove}
      onMouseLeave={resetPosition}
      ref={divRef}
    >
      {children}
    </ButtonWrapper>
  )
}

export default ButtonPosition
