import React from 'react'
import { useTranslation } from 'react-i18next'
import { styled } from 'styled-components'
import MobileApparition from '../MobileApparition'
import { Pill } from '../Pill'
// import { Decoration } from './Decoration'

const StyledSection = styled.section`
  width: calc(75vw - 25px);
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 120px;
  padding-right: 180px;
  gap: 20px;
  background: ${({ first, theme }) =>
    first ? theme.colors.white : theme.colors.grey1};
  border-radius: 72px;

  h4 {
    color: ${props => props.theme.colors.black};
    color: #000;
    font-size: 56px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%; /* 72.8px */
  }

  p {
    color: ${props => props.theme.colors.black};
    font-family: Satoshi Variable;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    opacity: 0.6;
    max-width: 720px;
  }

  span {
    color: ${props => props.theme.colors.black};
    font-size: 48px;
    font-style: normal;
    font-weight: 400;
    line-height: 64px; /* 133.333% */
  }

  @media (max-width: 1180px) {
    width: 100%;
    padding: 40px 80px;

    p {
      max-width: 100%;
    }
  }

  @media (max-width: 768px) {
    padding: 24px 40px;

    h4 {
      font-size: 32px;
    }
    p {
      font-size: 16px;
    }
    span {
      font-size: 32px;
    }
  }

  @media (max-width: 640px) {
    padding: 16px 24px;
    border-radius: 48px;

    h4 {
      font-size: 24px;
    }
    p {
      font-size: 14px;
    }
    span {
      font-size: 24px;
    }
  }
`

export const useGetSections = () => {
  const { t } = useTranslation()

  return [
    {
      title: t('services.first.title'),
      content: t('services.first.content'),
      adornment: <Pill label="Services" />,
    },
    {
      title: t('services.second.title'),
      content: t('services.second.content'),
      // adornment: <Decoration slug="design" />,
      pills: ['Figma', 'Adobe XD', 'Adobe Photoshop', 'Adobe Illustrator'],
    },
    {
      title: t('services.third.title'),
      content: t('services.third.content'),
      // adornment: <Decoration slug="development" />,
      pills: ['React', 'React Native', 'Node.js', 'GraphQL', 'MongoDB'],
    },
    {
      title: t('services.fourth.title'),
      content: t('services.fourth.content'),
      // adornment: <Decoration slug="branding" />,
      pills: ['Agile', 'Scrum', 'Kanban', 'Trello', 'Jira'],
    },
  ]
}

const ServiceSection = ({
  title,
  content,
  first,
  adornment,
  pills = [],
  index,
}) => {
  const pair = index % 2 === 0
  return (
    <MobileApparition
      direction={pair ? 'translateX(40px)' : 'translateX(-40px)'}
    >
      <StyledSection first={first}>
        {adornment}
        <div className="flex items-center max-w-[720px] gap-4">
          <h4>{title}</h4>
          {Boolean(index) && (
            <>
              <div className="flex flex-1 border-b border-black h-[60%]" />
              <span>{'00' + index}</span>
            </>
          )}
        </div>
        <p>{content}</p>
        <div className="flex gap-2.5 flex-wrap">
          {pills.map(pill => (
            <Pill key={pill} label={pill} />
          ))}
        </div>
      </StyledSection>
    </MobileApparition>
  )
}

export default ServiceSection
