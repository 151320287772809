import React, { createContext, useContext, useMemo, useState } from 'react'
import { Cursor } from './Cursor'

const CursorContext = createContext()

const CursorProvider = ({ children }) => {
  const [scale, setScale] = useState(1)
  const [customCursor, setCustomCursor] = useState(null)
  const [hideCursor, setHideCursor] = useState(null)

  const value = useMemo(() => {
    return {
      scale,
      setScale,
      customCursor,
      setCustomCursor,
      hideCursor,
      setHideCursor,
    }
  }, [scale, customCursor, hideCursor])

  return (
    <CursorContext.Provider value={value}>
      <Cursor
        hideCursor={hideCursor}
        scale={scale}
        customCursor={customCursor}
      />
      {children}
    </CursorContext.Provider>
  )
}

export const useCursorContext = () => {
  return useContext(CursorContext) || {}
}

export default CursorProvider
