import React, { useCallback } from 'react'
import { Interactive } from '../Cursor/Interactive'
import { Pill } from '../Pill'

const SelectPill = ({ label, onSelect, select }) => {
  return (
    <Interactive>
      <Pill label={label} hover={select} onClick={() => onSelect(label)} />
    </Interactive>
  )
}

export const SelectPills = ({
  value: values,
  keys,
  onChange,
  limit,
  ...props
}) => {
  const handleSelect = useCallback(
    value => {
      const select = values.includes(value)
      if (select) {
        return onChange(values.filter(v => v !== value))
      }

      if (values.length >= limit) {
        return onChange([...values.splice(1, values.length - 1), value])
      }

      return onChange([...values, value])
    },
    [onChange, values, limit],
  )

  return (
    <div className="flex flex-wrap gap-6">
      {keys.map(key => (
        <SelectPill
          key={key}
          label={key}
          select={values.includes(key)}
          onSelect={handleSelect}
          {...props}
        />
      ))}
    </div>
  )
}
