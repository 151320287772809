import { useControlScroll } from '@/Hooks'
import React, { createContext, useContext, useMemo } from 'react'

const ControlScrollContext = createContext()

const ControlScrollProvider = ({ children }) => {
  const onScrollTo = useControlScroll()

  const value = useMemo(() => {
    return {
      onScrollTo,
    }
  }, [onScrollTo])

  return (
    <ControlScrollContext.Provider value={value}>
      {children}
    </ControlScrollContext.Provider>
  )
}

export const useControlScrollContext = () => {
  return useContext(ControlScrollContext) || {}
}

export default ControlScrollProvider
