const translations = {
  header: {
    nav_item_1: 'Our services',
    nav_item_2: 'How it works',
    nav_item_3: 'Our projects',
    nav_item_4: 'Customer reviews',
    contact_us: 'Contact us',
    back: 'Back to home',
  },
  hero_section: {
    enterprise_name: 'Inprogress Agency',
    title: 'Your development and design <1>{{partner}}</1>',
    partner: 'partner',
    discover: 'Discover us',
  },
  strengths: {
    first: {
      title: 'Our ability to adapt',
      description:
        'We adapt to your needs and constraints. We are flexible and responsive.',
    },
    second: {
      title: 'Customized support',
      description:
        'We support you in the realization of your projects. We advise you and guide you.',
    },
    third: {
      title: 'Our strength of proposal',
      description:
        'We offer you innovative and creative solutions. We bring you our expertise.',
    },
  },
  services: {
    first: {
      title: 'A complete tech product team',
      content:
        'We are a team of developers, designers, and project managers. The goal is to excel in each field and to create high-quality digital products.',
    },
    second: {
      title: 'Design',
      content:
        "We take into account the user's interaction with the interface. We pay particular attention to hypothesis testing and prototyping; only the most successful solutions proceed to visualization.",
    },
    third: {
      title: 'Development',
      content:
        'We develop web and mobile applications. We use the latest technologies and best practices to create scalable and maintainable digital products over time.',
    },
    fourth: {
      title: 'Methodology',
      content:
        'Nothing makes sense without good organization. We use agile methods to manage our projects. We are transparent and communicate regularly with our customers to keep them informed of the progress of the project.',
    },
  },
  banner: {
    label: 'Discover our team',
    first: 'We are a creative 🎨 and passionate 🧠 team based in Paris 🏡',
    second:
      'The expertise of a team 🏢 combined with the proximity of a freelance 🧑‍💻',
  },
  how_it_works: {
    title: 'We offer you an ideal support from idea to realization',
    first: {
      title: 'Ideation',
      content:
        'We start by getting to know our clients, their goals, and their target audience.',
    },
    second: {
      title: 'Brainstorming',
      content:
        'We develop a strategy that defines the design approach, the user experience, and the main features of the project.',
    },
    third: {
      title: 'Design',
      content:
        'We work closely with our clients to get feedback and improve the design until it meets their needs and vision.',
    },
    fourth: {
      title: 'Development',
      content:
        'Once the design is validated, we frame the tasks in order to set up a development roadmap. Development can start under the best conditions.',
    },
    fifth: {
      title: 'Iteration',
      content:
        'We test the product with end users and you, then make the necessary changes to improve the user experience.',
    },
  },
  projects: {
    title: 'Selection of projects',
    subtitle:
      'We have worked with startups, SMEs, and large companies to create digital products that have an impact. We choose our clients as carefully as they choose us. We prefer long-term relationships based on trust and transparency.',
    first: {
      title: 'Groupe Le Monde',
      subtitle:
        'Long-term support within a product team for the design and maintenance of several internal products within the Le Monde group.',
      pills: {
        1: 'Consulting',
        2: 'Editor',
        3: 'CMS',
      },
    },
    second: {
      title: 'Rexel',
      subtitle:
        'An internal platform for the Rexelexpo France trade shows for booking and event management.',
      pills: {
        1: 'BtoB',
        2: 'Salesforce',
        3: 'Booking',
      },
    },
    budly_activity: {
      title: 'Budly - Interface',
      subtitle:
        "Budly is a mobile application that combines gamification, social networking and AI in the context of ecology. The user's activity allows them to plant trees in real life.",
      pills: { 1: 'Social network', 2: 'Gamification', 3: 'UX/UI Design' },
    },
    budly_games: {
      title: 'Budly - Games',
      subtitle:
        'In Budly, it is also possible to win rewards by playing mini-games or trying your luck at the casino.',
      pills: { 1: 'Mini-games', 2: 'Casino', 3: 'Ranking' },
    },
    third: {
      title: 'GoPopMe',
      subtitle:
        'GPM is a social network that centralizes your digital identity, social networks, and professional projects.',
      pills: {
        1: 'Social network',
        2: 'Multi-platform',
        3: 'Support',
      },
    },
    fourth: {
      title: 'XConnect',
      subtitle:
        'An internal platform for the Xelliss network to connect members to each other via personalized profiles and a news feed.',
      pills: {
        1: 'Interface',
        2: 'Utility',
        3: 'Dashboard',
      },
    },
    fifth: {
      title: 'Quadriplay',
      subtitle:
        'A tablet application for advertising in taxis. The application is used by more than 10,000 drivers.',
      pills: {
        1: 'Campaign',
        2: 'Advertising',
        3: 'Taxi',
      },
    },
    sixth: {
      title: 'Digital Card ID',
      subtitle:
        'A whitelabeled application tailored to the branding of each company for creating digital business cards.',
      pills: {
        1: 'Business card',
        2: 'Digital',
        3: 'Whitelabel',
      },
    },
    find: {
      title: 'Find',
      subtitle:
        'Find is a dating application that allows you to find people nearby to chat and meet.',
      pills: {
        1: 'Dating',
        2: 'Catalog',
        3: 'Messaging',
      },
    },
  },
  marquee: {
    label: 'Share your idea with us',
  },
  reviews: {
    title: 'Customer Reviews',
    subtitle: 'They trust us, why not you?',
    // baptiste: {
    //   content:
    //     'Leur force de proposition sur les enjeux techniques était vraiment un atout pour notre équipe et a contribué à la réussite du projet.',
    //   infos: 'Développeur, Freelance',
    // },
    // pierre: {
    //   content:
    //     'Très content à tous points de vue. Notre chiffre d’affaires a considérablement augmenté depuis la refonte du site.',
    //   infos: 'Fondateur, Font Vie',
    // },
    // david: {
    //   content:
    //     "Ils m’accompagnent depuis longtemps dans mes projets tech, vous pouvez faire confiance à l'équipe les yeux fermés. Très satisfait.",
    //   infos: 'CEO, GoPopMe',
    // },
    // nicolas: {
    //   content:
    //     "J'ai beaucoup aimé travailler avec Ilan de l’équipe Inprogress. Sa quête de performance et de vélocité est communicative et a permis à l'équipe d'atteindre ses objectifs.",
    //   infos: 'Product Owner, Le Monde',
    // },
    // charles: {
    //   content:
    //     "L’équipe saura rapidement définir vos besoins et vous apporter les solutions adéquates. Ils n'hésiteront pas non plus à vous donner leurs avis, toujours avec de bons arguments.",
    //   infos: 'CEO, TU COMMENCES DEMAIN',
    // },

    baptiste: {
      content:
        'Their strength of proposal on technical issues was really an asset for our team and contributed to the success of the project.',
      infos: 'Developer, Freelance',
    },
    pierre: {
      content:
        'Very happy in every way. Our turnover has increased considerably since the redesign of the site.',
      infos: 'Founder, Font Vie',
    },
    david: {
      content:
        'They have been accompanying me for a long time in my tech projects, you can trust the team with your eyes closed. Very satisfied.',
      infos: 'CEO, GoPopMe',
    },
    nicolas: {
      content:
        'I really enjoyed working with Ilan from the Inprogress team. His quest for performance and velocity is communicative and allowed the team to achieve its goals.',
      infos: 'Product Owner, Le Monde',
    },
    charles: {
      content:
        'The team will quickly define your needs and bring you the right solutions. They will not hesitate to give you their opinion, always with good arguments.',
      infos: 'CEO, TU COMMENCES DEMAIN',
    },
  },
  footer: {
    title: 'Inprogress Agency, your IT partner',
    top: 'Back to top',
  },
  team: {
    top: {
      title: 'Meet our team',
      subtitle:
        'Discover the members of our team and their roles within our agency.',
    },
    members: {
      ilan: 'Passionate entrepreneur, Ilan leads an agency as CEO and CTO, focusing on customer proximity and sharing his expertise.',
      paul: 'Paul, fullstack developer, has his roots in video games. His mastery of the Javascript ecosystem makes him a major asset within our team.',
      marco:
        'Marco, fullstack developer, has embraced development with passion. His versatility and thirst for learning make him an essential element.',
      aksel:
        'Aksel, in front-end training, shines with his enthusiasm and growing mastery of JavaScript, with a keen eye for detail.',
      elvan:
        'Elvan is a sharp fullstack JavaScript developer, with a penchant for React Native and a curiosity for AI.',
      julien:
        'Julien, seasoned designer, excels in interface design. With his innovative flair, he brings a unique touch to each project.',
      romain:
        'Romain, fullstack developer with a taste for the front. He shows rapid progression and great potential.',
      alexandre:
        'Alexandre is an intern as an assistant project manager, he is the link between clients and the development team.',
      vitor:
        'Vitor, an intern as an assistant project manager, is versatile, helps on many projects and has skills in SEO.',
    },
    banner: 'Want to be part of a dynamic team? Apply now! ✍️',
  },
  words: {
    creativity: 'Creativity',
    passion: 'Passion',
    expertise: 'Expertise',
    proximity: 'Proximity',
    adaptability: 'Adaptability',
    quality: 'Quality',
    performance: 'Performance',
    listen: 'Listen',
    create: 'Create',
    deploy: 'Deploy',
    passionate: 'Passionate',
    creative: 'Creative',
    versatile: 'Versatile',
  },
  contact: {
    blocs: {
      title_name: 'Let’s start by getting to know each other:',
      title_categories: 'You are interested in:',
      title_budgets: 'Your budget is between:',
      title_contact: 'How can we contact you?',
      title_content: 'Tell us about your project!',
    },
    placeholders: {
      name: 'Your full name *',
      email: 'Your email *',
      phone: 'Your phone',
      content: 'Tell us more...',
    },
    categories: {
      branding: 'Branding',
      website_redesign: 'Website Redesign',
      animation: 'Animation',
      mobile_app: 'Mobile App',
      web_app: 'Web App',
      e_commerce_site: 'E-commerce Site',
      showcase_site: 'Showcase Site',
      saas: 'SaaS',
      crm: 'CRM',
      features: 'Features',
      maintenance: 'Maintenance',
      video_games: 'Video Games',
      seo: 'SEO',
      security: 'Security',
      consulting: 'Consulting',
      ux_ui_design: 'UX/UI Design',
      training: 'Training',
      cms: 'CMS',
      content_writing: 'Content Writing',
      project_management: 'Project Management',
      staffing: 'Staffing',
      other: 'Other',
    },
    send_projects: 'Send your project',
    top_section: {
      title: 'Hello!',
      title_2: "Let's discuss your project 👋",
      subtitle:
        'A project to share with us? We look forward to meeting you. Tell us your needs & desires! You can also contact us on contact@inprogress.agency.',
    },
    toast: 'Your request has been sent, we will get back to you shortly.',
    banner_contact:
      'You can also contact us by email 📩 at the following address:',
  },
}

export default translations
