import React from 'react'
import { StyledFooter } from './StyledFooter'
import Logo from '../../Assets/images/logo.png'
import Solutions from './Solutions'
import Policy from './Policy'
import ArrowUp from '../../Assets/icons/up.svg'
import { Interactive } from '../Cursor/Interactive'
import { useControlScrollContext } from '../ControlScrollProvider'
import { useTranslation } from 'react-i18next'

const Footer = () => {
  const { onScrollTo } = useControlScrollContext()
  const { t } = useTranslation()

  return (
    <StyledFooter>
      <div className="container">
        <div className="left-navbar">
          <div className="flex flex-col gap-10 max-sm:items-center max-sm:justify-center">
            <img className="h-16 w-16" src={Logo} alt="logo" loading="lazy" />
            <span className="max-sm:text-center">{t('footer.title')}</span>
          </div>
          <Policy />
        </div>
        <div className="right-navbar">
          <Solutions />
          <div className="flex self-end">
            <Interactive>
              <div
                onClick={() => onScrollTo(0)}
                className="flex items-center gap-3"
              >
                <span className="text-white">{t('footer.top')}</span>
                <img
                  className="h-12 w-12 cursor-pointer"
                  src={ArrowUp}
                  alt="arrow"
                  loading="lazy"
                />
              </div>
            </Interactive>
          </div>
        </div>
      </div>
    </StyledFooter>
  )
}

export default Footer
