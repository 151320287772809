import React, { useEffect, useState } from 'react'
import { memo } from 'react'
import {
  Navigate,
  Outlet,
  RouterProvider,
  createBrowserRouter,
} from 'react-router-dom'
import { Layout } from '@/Components/Layout'
import * as routes from './routes'
import { Suspense } from 'react'
import { useScrollToTop } from '@/Hooks'

const MainLayout = ({ children }) => {
  const ready = useScrollToTop()
  const [isLoaded, setIsLoaded] = useState(false)

  useEffect(() => {
    if (!ready) return

    const timer = setTimeout(() => {
      setIsLoaded(true)
    }, 50)

    return () => clearTimeout(timer)
  }, [ready])

  if (!ready) return null
  return (
    <Layout className={isLoaded ? 'loaded' : ''}>
      <div className="min-h-screen">
        <Suspense fallback={null}>{children}</Suspense>
      </div>
    </Layout>
  )
}

const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <>
        <Outlet />
        <MainLayout>
          <routes.Home />
        </MainLayout>
      </>
    ),
  },
  {
    path: '/team',
    element: (
      <MainLayout>
        <routes.Team />
      </MainLayout>
    ),
  },
  {
    path: '/contact',
    element: (
      <MainLayout>
        <routes.Contact />
      </MainLayout>
    ),
  },
  {
    path: '*',
    element: <Navigate to="/" />,
  },
])

export const Router = memo(() => {
  return <RouterProvider router={router} />
})
