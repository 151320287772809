import { useHomeContext } from '@/Containers/Home/HomeContext'
import { useMobileContext } from '@/MobileContext'
import clsx from 'clsx'
import React, { useCallback, useEffect, useState, memo } from 'react'
import styled from 'styled-components'
import { useCursorContext } from '../Cursor/CursorContext'
import { Interactive } from '../Cursor/Interactive'
import Expendable from '../Expendable'
import MobileApparition from '../MobileApparition'
import { Pill } from '../Pill'
import { ImagesCursor } from './ImagesCursor'

const Title = styled.h4`
  color: ${props => props.theme.colors.black};
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 64px;

  @media (max-width: 1180px) {
    font-size: 38px;
    line-height: 46px;
  }

  @media (max-width: 768px) {
    font-size: 30px;
    line-height: 28px;
  }

  @media (max-width: 640px) {
    font-size: 22px;
    line-height: 26px;
  }
`

const MoreButton = styled.div`
  width: 128px;
  height: 128px;
  background-color: ${props => props.theme.colors.primary};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 1180px) {
    cursor: pointer !important;
  }

  @media (max-width: 768px) {
    width: 96px;
    height: 96px;
  }

  @media (max-width: 640px) {
    position: absolute;
    top: 44px;
    right: 0;
    width: 36px;
    height: 36px;
  }
`

const More = () => (
  <svg
    width="36"
    height="36"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6 12.75H18M12 6.75L12 18.75"
      stroke="#FFF"
      stroke-width="1.5"
      stroke-linecap="round"
    />
  </svg>
)

const Less = () => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 12.75H16"
      stroke="#FFF"
      stroke-width="1.5"
      stroke-linecap="round"
    />
  </svg>
)

const ListImages = memo(({ project }) => {
  return (
    <div className="flex flex-row flex-wrap gap-6 w-full max-sm:items-center max-sm:justify-center">
      {project.images.map((image, index) => (
        <img
          key={index}
          src={image}
          height={280}
          alt="project"
          className={clsx(
            'bg-grey1 flex rounded-lg object-contain max-md:w-auto max-md:max-h-[280px]',
            project.aspectRatio > 1 ? 'w-[40%]' : 'h-[280px]',
          )}
          loading="lazy"
        />
      ))}
    </div>
  )
})

const Project = memo(({ project, index }) => {
  const { mobile } = useMobileContext()
  const { setCustomCursor } = useCursorContext()
  const { scrollY, windowHeight } = useHomeContext()
  const [open, setOpen] = useState(false)
  const [hover, setHover] = useState(false)
  const { title } = project

  const onHover = useCallback(() => {
    if (open) return
    setHover(true)
    if (mobile) return
    setCustomCursor({ component: ImagesCursor, props: { project } })
  }, [project, setCustomCursor, setHover, mobile, open])

  const onLeave = useCallback(() => {
    setHover(false)
    setCustomCursor(null)
  }, [setCustomCursor, setHover])

  useEffect(() => {
    if (hover && !mobile && !open) {
      setCustomCursor(null)

      setCustomCursor({ component: ImagesCursor, props: { project } })
    }
  }, [hover, setCustomCursor, project, mobile, open])

  useEffect(() => {
    if (scrollY <= windowHeight * 23.1 || scrollY > windowHeight * 25.75) {
      setCustomCursor(null)
    }
    if (open) {
      setCustomCursor(null)
    }
  }, [open, setCustomCursor, scrollY, windowHeight])

  const pair = index % 2 === 0

  const toggleOpen = useCallback(() => {
    if (!open) {
      requestAnimationFrame(() => {
        setOpen(true)
      })
    } else {
      setOpen(false)
    }
  }, [open, setOpen])

  return (
    <MobileApparition
      direction={pair ? 'translateX(40px)' : 'translateX(-40px)'}
    >
      <div
        className={clsx(
          'flex flex-1 px-20 items-center gap-10',
          'max-xl:px-0',
          'max-sm:gap-0',
        )}
      >
        <div
          onClick={toggleOpen}
          className="flex flex-col py-12 flex-1 gap-5 max-xl:w-full relative"
          onMouseEnter={onHover}
          onMouseMove={onHover}
          onMouseLeave={onLeave}
        >
          <Title>{title}</Title>
          <Expendable open={open}>
            <div className="flex flex-col gap-6 mb-6">
              <p style={{ fontSize: '20px' }}>{project.subtitle}</p>
              <ListImages project={project} />
            </div>
          </Expendable>
          <div className="flex gap-3 flex-wrap">
            {project.pills.map(label => (
              <Pill hover={hover} label={label.toUpperCase()} />
            ))}
          </div>
        </div>
        <Interactive>
          <MoreButton onClick={() => setOpen(!open)}>
            {open ? <Less /> : <More />}
          </MoreButton>
        </Interactive>
      </div>
    </MobileApparition>
  )
})

export default Project
