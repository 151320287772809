import React, { createContext, useContext, useMemo } from 'react'
import { useWindowSize } from './Hooks'

const MobileContext = createContext()

const MobileProvider = ({ children }) => {
  const { width } = useWindowSize()
  const value = useMemo(() => {
    return {
      mobile: width && width < 1180,
    }
  }, [width])

  return (
    <MobileContext.Provider value={value}>{children}</MobileContext.Provider>
  )
}

export const useMobileContext = () => {
  return useContext(MobileContext) || {}
}

export default MobileProvider
