import promiseRetry from 'promise-retry'
import { lazy } from 'react'
const importWithRetry = importComponent => {
  return () =>
    promiseRetry(
      retry => {
        return importComponent().catch(retry)
      },
      { retries: 3, minTimeout: 1000 },
    )
}

export const Home = lazy(
  importWithRetry(() => import('@/Containers/routes/Home')),
)

export const Team = lazy(
  importWithRetry(() => import('@/Containers/routes/Team')),
)

export const Contact = lazy(
  importWithRetry(() => import('@/Containers/routes/Contact')),
)
