import { useHomeContext } from '@/Containers/Home/HomeContext'
import { useMobileContext } from '@/MobileContext'
import React from 'react'

export const PathMask = ({ svgRef, progress }) => {
  const { mobile } = useMobileContext()
  const { homeStyles, windowHeight } = useHomeContext()
  const { maskRef } = homeStyles

  if (!maskRef?.current || !svgRef?.current) return null

  const {
    maskWidthInViewBox,
    maskHeightInViewBox,
    maskXInViewBox,
    maskYInViewBox,
  } = calculateMaskDimensions(svgRef, maskRef, windowHeight)

  const INITIAL_MASK_WIDTH = 23.570926853867828
  const RY_INITIAL = 7
  const RX_INITIAL = 5

  const ryFactor = RY_INITIAL / INITIAL_MASK_WIDTH
  const rxFactor = RX_INITIAL / INITIAL_MASK_WIDTH

  const adjustedRY = maskWidthInViewBox * ryFactor
  const adjustedRX = maskWidthInViewBox * rxFactor

  return (
    <mask id="pathMask">
      <rect width="100%" height="100%" fill="white" />
      <rect
        x={maskXInViewBox}
        y={maskYInViewBox}
        width={progress && !mobile > 0.5 ? 500 : maskWidthInViewBox}
        height={progress && !mobile > 0.5 ? 500 : maskHeightInViewBox}
        ry={adjustedRY}
        rx={adjustedRX}
        fill="black"
      />
    </mask>
  )
}

const calculateMaskDimensions = (svgRef, maskRef) => {
  const maskPosition = maskRef?.current.getBoundingClientRect()

  const svgWidthInPixels = svgRef?.current.clientWidth
  const svgHeightInPixels = svgRef?.current.clientHeight
  const widthRatio = 100 / svgWidthInPixels
  const heightRatio = 150 / svgHeightInPixels

  const svgPosition = svgRef?.current.getBoundingClientRect()
  const relativeMaskXInPixels = maskPosition.left - svgPosition.left
  const relativeMaskYInPixels = maskPosition.top - svgPosition.top

  return {
    maskWidthInViewBox: maskPosition.width * widthRatio || 0,
    maskHeightInViewBox: maskPosition.height * heightRatio || 0,
    maskXInViewBox: relativeMaskXInPixels * widthRatio || 0,
    maskYInViewBox: relativeMaskYInPixels * heightRatio || 0,
  }
}
