import React, { memo, useEffect, useMemo, useRef } from 'react'
import SvgArrowTopRight from '@/Assets/icons/components/ArrowTopRight'
import { styled } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useCallback } from 'react'
import { useHomeContext } from '@/Containers/Home/HomeContext'
import { Interactive } from './Cursor/Interactive'
import ButtonPosition from './ButtonPosition'
import { useControlScrollContext } from './ControlScrollProvider'
import { useMobileContext } from '@/MobileContext'

const CTAButton = styled.button`
  position: relative;
  background-color: ${props => props.theme.colors.primary};
  color: ${props => props.theme.colors.white};
  padding: 40px 50px 40px 60px;
  border-radius: 999px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  font-family: 'Satoshi Variable', sans-serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  z-index: 1;

  @media (max-width: 640px) {
    font-size: 20px;
    padding: 20px 30px 20px 40px;
  }
`

const Mask = styled.div`
  position: absolute;
  background-color: ${props => props.theme.colors.primary};
  border-radius: 999px;
  width: 100%;
  height: 100%;
  will-change: transform;
`

const handleClipPath = (ref, percentage) => {
  if (ref?.current?.style) {
    ref.current.style.clipPath = `polygon(0% ${percentage}%, 100% ${percentage}%, 100% 100%, 0% 100%)`
  }
}

const HomeButton = memo(
  ({ progress, centeredTextWhiteRef, enterpriseTextWhiteRef }) => {
    const { windowHeight, homeStyles, setHomeStyles, scrollY } =
      useHomeContext()
    const maskRef = useRef(null)
    const { t } = useTranslation()
    const { mobile } = useMobileContext()

    const handleScroll = useCallback(() => {
      if (scrollY >= windowHeight * 4) return

      const factor = 0.00347
      const baseScale = 1
      const maxScaleIncrement =
        Math.pow(Math.E, factor * (windowHeight / 1.44)) - 1
      const someMaxValue = baseScale + maxScaleIncrement

      if (
        maskRef?.current &&
        centeredTextWhiteRef?.current &&
        enterpriseTextWhiteRef?.current
      ) {
        const maskTopPosition = maskRef?.current.getBoundingClientRect().top
        const textRect = centeredTextWhiteRef?.current.getBoundingClientRect()
        const subTextRect =
          enterpriseTextWhiteRef?.current.getBoundingClientRect()

        const mappedScrollY = progress * (windowHeight * 2)
        const scaleIncrement = Math.pow(Math.E, factor * mappedScrollY) - 1
        const scale = baseScale + scaleIncrement
        const textPositionFromTop = textRect.top
        const textHeight = textRect.height
        const subTextPositionFromTop = subTextRect.top
        const subTextHeight = subTextRect.height

        const coveredTextAmount = Math.max(
          0,
          textPositionFromTop + textHeight - maskTopPosition,
        )
        const coveredSubTextAmount = Math.max(
          0,
          subTextPositionFromTop + subTextHeight - maskTopPosition,
        )

        const uncoveredTextPercentage = Math.min(
          100,
          ((textHeight - coveredTextAmount) / textHeight) * 100,
        )
        const uncoveredSubTextPercentage = Math.min(
          100,
          ((subTextHeight - coveredSubTextAmount) / subTextHeight) * 100,
        )

        handleClipPath(centeredTextWhiteRef, uncoveredTextPercentage)
        handleClipPath(enterpriseTextWhiteRef, uncoveredSubTextPercentage)

        if (scale >= someMaxValue) {
          setHomeStyles(state => ({
            ...state,
            scaleButton: someMaxValue,
            buttonAnimationFinish: true,
            maskRef,
          }))

          return
        }

        setHomeStyles(state => ({
          ...state,
          maskRef,
          scaleButton: scale,
          buttonAnimationFinish: false,
        }))
      }
    }, [
      windowHeight,
      setHomeStyles,
      centeredTextWhiteRef,
      enterpriseTextWhiteRef,
      progress,
      scrollY,
    ])

    useEffect(() => {
      const rafId = requestAnimationFrame(handleScroll)
      return () => cancelAnimationFrame(rafId)
    }, [scrollY, handleScroll, setHomeStyles])

    const maskStyles = useMemo(
      () => ({
        top: 0,
        transform: `scale(${homeStyles?.scaleButton})`,
      }),
      [homeStyles?.scaleButton],
    )

    const { onScrollTo } = useControlScrollContext()

    return (
      <ButtonPosition disabled={scrollY > 500}>
        <Interactive>
          <CTAButton
            onClick={() =>
              onScrollTo(!mobile ? windowHeight * 2.38 : windowHeight * 1.48)
            }
          >
            {t('hero_section.discover')}
            <SvgArrowTopRight size={mobile ? 16 : 24} />
          </CTAButton>
        </Interactive>
        <Mask ref={maskRef} style={maskStyles} />
      </ButtonPosition>
    )
  },
)

export default HomeButton
