import React from 'react'
import { Interactive } from '../Cursor/Interactive'

const Policy = () => {
  const policy = [
    {
      title: 'Privacy Policy',
      url: ' ',
    },
    {
      title: 'Terms',
      url: ' ',
    },
  ]

  return (
    <ul className="flex items-center gap-8 h-12 max-sm:h-56 max-sm:flex-col">
      {policy.map(index => {
        return (
          <li className="list-none	 cursor-pointer leading-6 text-base	">
            <a
              onClick={e => e.preventDefault()}
              href="https://inprogress.agency/legacy"
              className="text-[#808080] hover:text-white"
            >
              {index.title}
            </a>
          </li>
        )
      })}
      <li className="text-white cursor-pointer text-base list-none">
        <Interactive>
          <a href="mailto:contact@inprogress.agency" aria-label="Contact us">
            contact@inprogress.agency
          </a>
        </Interactive>
      </li>
    </ul>
  )
}

export default Policy
