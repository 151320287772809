const translations = {
  header: {
    nav_item_1: 'Nos services',
    nav_item_2: 'Comment ça marche',
    nav_item_3: 'Nos projets',
    nav_item_4: 'Avis clients',
    contact_us: 'Nous contacter',
    back: "Retour à l'accueil",
  },
  hero_section: {
    enterprise_name: 'Inprogress Agency',
    title: 'Votre <1>{{partner}}</1> développement et design',
    partner: 'partenaire',
    discover: 'Nous découvrir',
  },
  strengths: {
    first: {
      title: 'Notre capacité d’adaptation',
      description:
        'Nous nous adaptons à vos besoins et à vos contraintes. Nous sommes flexibles et réactifs.',
    },
    second: {
      title: 'Un accompagnement sur mesure',
      description:
        'Nous vous accompagnons dans la réalisation de vos projets. Nous vous conseillons et vous guidons.',
    },
    third: {
      title: 'Notre force de proposition',
      description:
        'Nous vous proposons des solutions innovantes et créatives. Nous vous apportons notre expertise.',
    },
  },
  services: {
    first: {
      title: 'Une équipe produit tech complète',
      content:
        "Nous sommes une équipe de développeurs, designers et chefs de projet. L'objectif est d'exceller dans chaque domaine et de créer des produits numériques de haute qualité.",
    },
    second: {
      title: 'Design',
      content:
        "Nous prenons en compte l'interaction de l'utilisateur avec l'interface. Nous accordons une attention particulière aux tests d'hypothèses et au prototypage, seules les solutions les plus réussies sont retenues pour la visualisation.",
    },
    third: {
      title: 'Développement',
      content:
        'Nous développons des applications Web et mobiles. Nous utilisons les dernières technologies et les meilleures pratiques pour créer des produits numériques scalable et maintenable dans le temps.',
    },
    fourth: {
      title: 'Méthodologie',
      content:
        "Rien à de sens sans une bonne organisation. Nous utilisons les méthodes agiles pour gérer nos projets. Nous sommes transparents et communiquons régulièrement avec nos clients pour les tenir informés de l'avancement du projet.",
    },
  },
  banner: {
    label: 'Découvrez notre équipe',
    first:
      'Nous sommes une équipe créative 🎨 et passionnée 🧠 basée à Paris 🏡',
    second:
      'L’expertise d’une équipe 🏢 alliée à la proximité d’un freelance 🧑‍💻',
  },
  how_it_works: {
    title:
      "Nous vous proposons un accompagnement idéal de l'idée à la réalisation",
    first: {
      title: 'Idéation',
      content:
        'Nous commençons par apprendre à connaître nos clients, leurs objectifs et leur public cible.',
    },
    second: {
      title: 'Brainstorming',
      content:
        "Nous élaborons une stratégie qui définit l'approche de la conception, l'expérience utilisateur et les principales caractéristiques du projet.",
    },
    third: {
      title: 'Design',
      content:
        "Nous travaillons en étroite collaboration avec nos clients afin d'obtenir des commentaires et d'améliorer la conception jusqu'à ce qu'elle réponde à leurs besoins et à leur vision.",
    },
    fourth: {
      title: 'Développement',
      content:
        'Une fois le design validé, nous cadrons les tâches afin de mettre en place une roadmap de développement. Le développement peut commencer dans les meilleures conditions.',
    },
    fifth: {
      title: 'Itération',
      content:
        'Nous testons le produit avec les utilisateurs finaux et vous, puis apportons les modifications nécessaires pour améliorer l’expérience utilisateur.',
    },
  },
  projects: {
    title: 'Sélection de projets',
    subtitle:
      'Nous avons travaillé avec des startups, des PME et des grandes entreprises pour créer des produits numériques qui ont un impact. Nous choisissons nos clients aussi soigneusement qu’ils nous choisissent. Nous préferons les relations à long terme basées sur la confiance et la transparence.',
    first: {
      title: 'Groupe Le Monde',
      subtitle:
        "Accompagnement long terme au sein d'une équipe produit pour la conception et la maintenance de plusieurs produits internes au groupe Le Monde.",
      pills: {
        1: 'Consultation',
        2: 'Éditeur',
        3: 'CMS',
      },
    },
    second: {
      title: 'Rexel',
      subtitle:
        "Une plateforme de réservation et de gestion d'événement pour les salons de Rexelexpo France.",
      pills: {
        1: 'BtoB',
        2: 'Salesforce',
        3: 'Réservation',
      },
    },
    budly_activity: {
      title: 'Budly - Interface',
      subtitle:
        "Budly est une application mobile, alliant gamificiation, réseau social et IA dans le cadre de l'écologie. L'activité de l'utilisateur permet de planter des arbres dans la vraie vie.",
      pills: {
        1: 'Réseau social',
        2: 'Gamification',
        3: 'UX/UI Design',
      },
    },
    budly_games: {
      title: 'Budly - Jeux',
      subtitle:
        'Dans Budly, il est aussi possible de gagner des récompenses en jouant à des mini-jeux ou en tentant sa chance au casino.',
      pills: {
        1: 'Mini-jeux',
        2: 'Casino',
        3: 'Classement',
      },
    },
    third: {
      title: 'GoPopMe',
      subtitle:
        'GPM est un réseau social servant à centraliser son identité digitale, ses réseaux sociaux et ses projets professionnels.',
      pills: {
        1: 'Réseau social',
        2: 'Multi-plateforme',
        3: 'Accompagnement',
      },
    },
    fourth: {
      title: 'XConnect',
      subtitle:
        "Une plateforme interne au réseau Xelliss pour connecter les membres entre eux via des profils personnalisés et un fil d'actualité.",
      pills: {
        1: 'Interface',
        2: 'Utilitaire',
        3: 'Dashboard',
      },
    },
    fifth: {
      title: 'Quadriplay',
      subtitle:
        "Une application tablette de diffusion de publicité pour les taxis. L'application est utilisée par plus de 10000 chauffeurs.",
      pills: {
        1: 'Campagne',
        2: 'Publicité',
        3: 'Taxi',
      },
    },
    sixth: {
      title: 'Digital Card ID',
      subtitle:
        'Une application whitelabelisée avec le branding de chaque entreprise afin de créer des cartes de visite numériques.',
      pills: {
        1: 'Carte de visite',
        2: 'Digital',
        3: 'Whitelabel',
      },
    },
    find: {
      title: 'Find',
      subtitle:
        'Find est une application de rencontre qui permet de trouver des personnes à proximité pour discuter et se rencontrer.',
      pills: {
        1: 'Rencontre',
        2: 'Catalogue',
        3: 'Messagerie',
      },
    },
  },
  marquee: {
    label: 'Partagez nous votre idée',
  },
  reviews: {
    title: 'Avis clients',
    subtitle: 'Ils nous font confiance, pourquoi pas vous ?',
    baptiste: {
      content:
        'Leur force de proposition sur les enjeux techniques était vraiment un atout pour notre équipe et a contribué à la réussite du projet.',
      infos: 'Développeur, Freelance',
    },
    pierre: {
      content:
        'Très content à tous points de vue. Notre chiffre d’affaires a considérablement augmenté depuis la refonte du site.',
      infos: 'Fondateur, Font Vie',
    },
    david: {
      content:
        "Ils m’accompagnent depuis longtemps dans mes projets tech, vous pouvez faire confiance à l'équipe les yeux fermés. Très satisfait.",
      infos: 'CEO, GoPopMe',
    },
    nicolas: {
      content:
        "J'ai beaucoup aimé travailler avec Ilan de l’équipe Inprogress. Sa quête de performance et de vélocité est communicative et a permis à l'équipe d'atteindre ses objectifs.",
      infos: 'Product Owner, Le Monde',
    },
    charles: {
      content:
        "L’équipe saura rapidement définir vos besoins et vous apporter les solutions adéquates. Ils n'hésiteront pas non plus à vous donner leurs avis, toujours avec de bons arguments.",
      infos: 'CEO, TU COMMENCES DEMAIN',
    },
  },
  footer: {
    title: 'Inprogress Agency, votre partenaire IT',
    top: 'Retour en haut',
  },

  team: {
    top: {
      title: 'Rencontrer notre équipe',
      subtitle:
        'Découvrez les membres de notre équipe et leurs rôles au sein de notre agence.',
    },
    members: {
      ilan: "Entrepreneur passionné, Ilan dirige une agence en tant que CEO et CTO, mettant l'accent sur la proximité client et le partage de son expertise.",
      paul: "Paul, développeur fullstack, puise ses origines dans le jeu vidéo. Sa maîtrise de l'écosystème Javascript fait de lui un atout majeur au sein de notre équipe.",
      marco:
        'Marco, développeur fullstack, a embrassé le développement avec passion. Sa polyvalence et sa soif d’apprendre font de lui un élément essentiel.',
      aksel:
        'Aksel, en formation front-end, brille par son enthousiasme et sa maîtrise croissante du JavaScript, avec un œil attentif aux détails.',
      elvan:
        "Elvan est un développeur fullstack pointu en JavaScript, avec un penchant pour React Native et une curiosité pour l'IA.",
      julien:
        "Julien, designer aguerri, excelle en conception d'interfaces. Avec son flair innovant, il apporte une touche unique à chaque projet.",
      romain:
        'Romain, développeur fullstack avec une appétence pour le front. Il affiche une progression rapide et un grand potentiel.',
      alexandre:
        "Alexandre est en stage en tant qu'assistant chef de projet, il est le lien entre les clients et l'équipe de développement.",
      vitor:
        "Vitor, en stage en tant qu'assistant chef de projet, il est polyvalent, apporte son aide sur de nombreux projets et a des compétences en SEO.",
    },
    banner:
      "Envie de faire partie d'une équipe dynamique ? Postule dès maintenant ! ✍🏻",
  },

  words: {
    creativity: 'Créativité',
    passion: 'Passion',
    expertise: 'Expertise',
    adaptability: 'Adaptabilité',
    proximity: 'Proximité',
    quality: 'Qualité',
    performance: 'Performance',
    listen: 'Écouter',
    create: 'Créer',
    deploy: 'Déployer',
    passionate: 'Passionnée',
    creative: 'Créative',
    versatile: 'Polyvalente',
  },
  contact: {
    blocs: {
      title_name: 'Commençons par faire connaissance :',
      title_categories: 'Vous êtes intéressé par :',
      title_budgets: 'Votre budget est situé entre :',
      title_contact: 'Comment peut-on vous contacter ?',
      title_content: 'Parlez-nous de votre projet !',
    },
    placeholders: {
      name: 'Votre nom complet *',
      email: 'Votre email *',
      phone: 'Votre téléphone',
      content: 'Dites-nous en plus...',
    },
    categories: {
      branding: 'Branding',
      website_redesign: 'Refonte de site',
      animation: 'Animation',
      mobile_app: 'Application Mobile',
      web_app: 'Application web',
      e_commerce_site: 'Site e-commerce',
      showcase_site: 'Site vitrine',
      saas: 'SaaS',
      crm: 'CRM',
      features: 'Fonctionnalités',
      maintenance: 'Maintenance',
      video_games: 'Jeux vidéos',
      seo: 'Référencement',
      security: 'Sécurité',
      consulting: 'Conseil',
      ux_ui_design: 'UX/UI Design',
      training: 'Formation',
      cms: 'CMS',
      content_writing: 'Rédaction de contenu',
      project_management: 'Gestion de projet',
      staffing: 'Staffing',
      other: 'Autre',
    },
    send_projects: 'Envoyer votre projet',
    top_section: {
      title: 'Bonjour !',
      title_2: 'Discutons de votre projet 👋',
      subtitle:
        'Un projet à nous faire part ? Nous avons hâte de vous rencontrer. Révélez nous vos besoins & vos envies ! Vous pouvez aussi nous contacter sur contact@inprogress.agency.',
    },
    toast: 'Votre demande a bien été envoyée, nous vous répondrons sous peu.',
    banner_contact:
      'Vous pouvez aussi nous contacter par mail 📩 à l’adresse suivante :',
  },
}

export default translations
