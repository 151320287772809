import React from 'react'

const FlagFr = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_988_8906)">
        <rect width="28" height="28" rx="14" fill="white" />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M-5.6001 0H7.46657V28H-5.6001V0Z"
          fill="#1A47B8"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M20.5334 0H33.6001V28H20.5334V0Z"
          fill="#F93939"
        />
      </g>
      <defs>
        <clipPath id="clip0_988_8906">
          <rect width="28" height="28" rx="14" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default FlagFr
