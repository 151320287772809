import React from 'react'

const FlagEn = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_988_12306)">
        <rect width="28" height="28" rx="14" fill="#1A47B8" />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M-1.43002 0H-5.6001V4.66667L29.4072 28L33.5999 28V23.3333L-1.43002 0Z"
          fill="white"
        />
        <path
          d="M-4.20925 0L33.5999 25.2661V28H32.2417L-5.6001 2.70771V0H-4.20925Z"
          fill="#F93939"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M29.8666 0H33.5999V4.66667C33.5999 4.66667 9.35154 20.2124 -1.86676 28H-5.6001V23.3333L29.8666 0Z"
          fill="white"
        />
        <path
          d="M33.5999 0H32.3339L-5.6001 25.2879V28H-4.20925L33.5999 2.72816V0Z"
          fill="#F93939"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M8.65578 0H19.3766V8.63673H33.5999V19.3576H19.3766V28H8.65578V19.3576H-5.6001V8.63673H8.65578V0Z"
          fill="white"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M10.9052 0H17.0946V10.7692H33.5999V17.2308H17.0946V28H10.9052V17.2308H-5.6001V10.7692H10.9052V0Z"
          fill="#F93939"
        />
      </g>
      <defs>
        <clipPath id="clip0_988_12306">
          <rect width="28" height="28" rx="14" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default FlagEn
