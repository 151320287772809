import { useCallback, useEffect, useRef } from 'react'

const useControlScroll = () => {
  const isScrollingTo = useRef(false) // Pour déterminer si un scrollTo est en cours

  const currentScroll = useRef(0)
  const targetScroll = useRef(0)
  const DAMPING = 0.05

  const animateScroll = useCallback(() => {
    const diff = targetScroll.current - currentScroll.current
    if (Math.abs(diff) > 0.5) {
      currentScroll.current += diff * DAMPING
      window.scrollTo(0, currentScroll.current)
      requestAnimationFrame(animateScroll)
    } else {
      isScrollingTo.current = false
    }
  }, [])

  const handleWheel = useCallback(e => {
    // e.preventDefault()
    // if (isScrollingTo.current) {
    //   return // Si un scrollTo est en cours, on arrête le traitement du wheel
    // }
    // const SCROLL_SPEED = (Math.abs(e.deltaY) / 100) * 16
    // const deltaY = e.deltaY > 0 ? SCROLL_SPEED : -SCROLL_SPEED
    // targetScroll.current += deltaY
    // targetScroll.current = Math.max(0, targetScroll.current) // Ne pas scroller en dessous de 0
    // targetScroll.current = Math.min(
    //   targetScroll.current,
    //   document.documentElement.scrollHeight - window.innerHeight,
    // ) // Ne pas scroller au-delà de la hauteur de la page
    // animateScroll()
  }, [])

  const handleTouchMove = useCallback(e => {
    // e.preventDefault()
    // const deltaY = e.changedTouches[0].clientY > 0 ? 16 : -16
    // targetScroll.current += deltaY
    // targetScroll.current = Math.max(0, targetScroll.current) // Ne pas scroller en dessous de 0
    // targetScroll.current = Math.min(
    //   targetScroll.current,
    //   document.documentElement.scrollHeight - window.innerHeight,
    // ) // Ne pas scroller au-delà de la hauteur de la page
    // animateScroll()
  }, [])

  const onScrollTo = y => {
    // targetScroll.current = y
    // isScrollingTo.current = true // On indique qu'un scrollTo est en cours

    // targetScroll.current = Math.max(0, targetScroll.current) // Ne pas scroller en dessous de 0
    // targetScroll.current = Math.min(
    //   targetScroll.current,
    //   document.documentElement.scrollHeight - window.innerHeight,
    // ) // Ne pas scroller au-delà de la hauteur de la page
    // animateScroll()
    window.scrollTo({
      top: y,
      behavior: 'smooth',
    })
  }

  useEffect(() => {
    window.addEventListener('wheel', handleWheel, {
      passive: false,
    })
    window.addEventListener('touchmove', handleTouchMove, {
      passive: false,
    })

    return () => {
      window.removeEventListener('wheel', handleWheel)
      window.removeEventListener('touchmove', handleTouchMove)
    }
  }, [handleWheel, handleTouchMove])

  return onScrollTo
}

export default useControlScroll
