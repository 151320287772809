import { useTranslation } from 'react-i18next'
import Input from '../Controls/Input'
import TextArea from '../Controls/TextArea'
import { SelectPills } from './SelectPills'

export const useGetFieldBlocs = () => {
  const { t } = useTranslation()
  return [
    {
      title: t('contact.blocs.title_name'),
      fields: [
        {
          component: Input,
          type: 'text',
          name: 'name',
          placeholder: t('contact.placeholders.name'),
          required: true,
        },
      ],
    },
    {
      title: t('contact.blocs.title_categories'),
      fields: [
        {
          component: SelectPills,
          name: 'categories',
          required: true,
          keys: [
            t('contact.categories.branding'),
            t('contact.categories.website_redesign'),
            t('contact.categories.animation'),
            t('contact.categories.mobile_app'),
            t('contact.categories.web_app'),
            t('contact.categories.e_commerce_site'),
            t('contact.categories.showcase_site'),
            t('contact.categories.saas'),
            t('contact.categories.crm'),
            t('contact.categories.features'),
            t('contact.categories.maintenance'),
            t('contact.categories.video_games'),
            t('contact.categories.seo'),
            t('contact.categories.security'),
            t('contact.categories.consulting'),
            t('contact.categories.ux_ui_design'),
            t('contact.categories.training'),
            t('contact.categories.cms'),
            t('contact.categories.content_writing'),
            t('contact.categories.project_management'),
            t('contact.categories.staffing'),
            t('contact.categories.other'),
          ],
          limit: 5,
        },
      ],
    },
    {
      title: t('contact.blocs.title_budgets'),
      fields: [
        {
          component: SelectPills,
          name: 'budgets',
          required: true,
          keys: ['1-10K€', '10-20K€', '20-50K€', '50-100K€', '100K€ +'],
          limit: 1,
        },
      ],
    },
    {
      title: t('contact.blocs.title_contact'),
      fields: [
        {
          component: Input,
          type: 'email',
          name: 'email',
          placeholder: t('contact.placeholders.email'),
          required: true,
        },
        {
          component: Input,
          type: 'tel',
          name: 'phone',
          placeholder: t('contact.placeholders.phone'),
          required: false,
        },
      ],
    },
    {
      title: t('contact.blocs.title_content'),
      fields: [
        {
          component: TextArea,
          name: 'content',
          placeholder: t('contact.placeholders.content'),
          required: true,
        },
      ],
    },
  ]
}
