import React from 'react'
import { useSpring, animated } from 'react-spring'
import styled from 'styled-components'
import { useMeasure } from 'react-use'

const Content = styled(animated.div)`
  overflow: hidden;
`

const Expandable = ({ open, children }) => {
  const [ref, { height: viewHeight }] = useMeasure()
  const { height, opacity } = useSpring({
    from: { height: 0, opacity: 0 },
    to: {
      height: open ? viewHeight : 0,
      opacity: open ? 1 : 0,
    },
  })

  return (
    <Content style={{ height, opacity }}>
      <div ref={ref}>{children}</div>
    </Content>
  )
}

export default Expandable
