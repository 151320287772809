import gsap from 'gsap'
import React, { memo, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import Bubble from './Bubble'
import { PathMask } from './PathMask'

const useGetBubbles = () => {
  const { t } = useTranslation()
  return [
    {
      x: '80%',
      y: '20%',
      word: t('words.creativity'),
      color: '#FFEDD2',
      size: 170,
      rotate: 10,
      visiblePercent: 0.13,
    },
    {
      x: '85%',
      y: '35%',
      word: t('words.passion'),
      color: '#FFD8F4',
      size: 220,
      rotate: 20,
      visiblePercent: 0.21,
    },
    {
      x: '75%',
      y: '48%',
      word: t('words.expertise'),
      color: '#CFFFF1',
      size: 140,
      rotate: -10,
      visiblePercent: 0.26,
    },
    {
      x: '5%',
      y: '30%',
      word: t('words.adaptability'),
      color: '#FFFAC9',
      size: 180,
      rotate: -22,
      visiblePercent: 0.47,
    },
    {
      x: '22%',
      y: '42%',
      word: t('words.proximity'),
      color: '#E0E7FF',
      size: 140,
      rotate: -10,
      visiblePercent: 0.57,
    },
    {
      x: '7%',
      y: '50%',
      word: t('words.performance'),
      color: '#FFD9D9',
      size: 190,
      rotate: 15,
      visiblePercent: 0.65,
    },
  ]
}

const StyledPath = styled.div`
  top: 0;
  left: 0;
  width: 100vw;
  height: 150vh;
  z-index: 40;
  pointer-events: none;
  will-change: transform;
  position: absolute;
  transform: translateY(-2px);
  /* display: flex; */
  justify-content: center;
`

const Line = styled.path`
  stroke-dasharray: ${({ pathLength }) => `${pathLength} ${pathLength}`};
  stroke-dashoffset: ${({ scrollProgress, pathLength }) =>
    (1 - scrollProgress) * pathLength};
  will-change: stroke-dashoffset;
`

const HeroPath = memo(({ progress }) => {
  const svgRef = useRef()
  const pathRef = useRef()
  const containerRef = useRef()
  const gsapRef = useRef()
  const [pathLength, setPathLength] = useState(0)

  useEffect(() => {
    if (pathRef?.current) {
      const length = pathRef?.current.getTotalLength()
      setPathLength(length)
    }
  }, [pathRef])

  useEffect(() => {
    const container = containerRef.current
    gsapRef.current = gsap.context(() => {
      const tl = gsap.timeline({
        repeat: -1,
        yoyo: true,
        defaults: {
          duration: 4,
          ease: 'power1.inOut',
        },
      })

      tl.fromTo(
        container,
        { scaleX: 1, transformOrigin: '50% 50%', y: 0 },
        { scaleX: 1.04, y: -5 },
      ).fromTo(
        container,
        { scaleX: 1.04, transformOrigin: '50% 50%', y: -5 },
        { scaleX: 0.99, y: 0 },
      )
    })

    return () => {
      if (gsapRef.current) {
        gsapRef.current.revert()
      }
    }
  }, [])

  const bubblePositions = useGetBubbles()
  return (
    <StyledPath
      ref={containerRef}
      className={pathLength ? 'opacity-100' : 'opacity-0'}
    >
      {bubblePositions.map((bubble, index) => {
        return (
          <Bubble
            scrollProgress={progress}
            key={index}
            style={{ left: bubble.x, top: bubble.y }}
            {...bubble}
          />
        )
      })}
      <svg
        ref={svgRef}
        width="100%"
        height="150vh"
        viewBox="0 0 100 150"
        fill="none"
        preserveAspectRatio="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <PathMask svgRef={svgRef} progress={progress} />
        <Line
          ref={pathRef}
          pathLength={pathLength}
          scrollProgress={progress}
          d="M50 0 C72 15.2 98 47.5 94 67.8 C86 96.1 0 68.4 6 56.2 C12 44.8 30 59.5 28 65.6 C26 71.7 10 79.1 12 88.2 C14 97.3 48 82.9 51 86.7 C56 90.5 56 97.3 47 113.5 L47 113.5 C33 135.25 7 143.8 -7 153"
          stroke="white"
          strokeWidth="0.2"
        />
        <Line
          ref={pathRef}
          pathLength={pathLength}
          scrollProgress={progress}
          d="M50 0 C72 15.2 98 47.5 94 67.8 C86 96.1 0 68.4 6 56.2 C12 44.8 30 59.5 28 65.6 C26 71.7 10 79.1 12 88.2 C14 97.3 48 82.9 51 86.7 C56 90.5 56 97.3 47 113.5 L47 113.5 C33 135.25 7 143.8 -7 153"
          stroke="black"
          strokeWidth="0.2"
          mask="url(#pathMask)"
        />
      </svg>
    </StyledPath>
  )
})

export default HeroPath
