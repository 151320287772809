import React from 'react'
import { styled } from 'styled-components'

const StyledPill = styled.div`
  padding: 16px 32px;
  white-space: nowrap;
  border-radius: 72px;
  border: 2px solid ${({ theme, color }) => color || theme.colors.primary};
  width: fit-content;
  font-family: Satoshi Variable;
  font-weight: 600;
  background: ${({ hover, theme, color }) =>
    hover ? color || theme.colors.primary : 'none'};
  color: ${({ hover, theme, color }) =>
    hover ? theme.colors.white : color || theme.colors.primary};
  transition: all 0.3s ease-in-out;
  align-items: center;
  display: flex;

  @media (max-width: 768px) {
    padding: 12px 24px;
    font-size: 14px;
  }
`
export const Pill = ({ label, hover, ...props }) => {
  return (
    <StyledPill hover={hover} {...props}>
      {label}
    </StyledPill>
  )
}
