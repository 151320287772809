import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import Loading from './Loading'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { ScrollSmoother } from 'gsap/ScrollSmoother'
import { useMobileContext } from '@/MobileContext'
import Observer from 'gsap/Observer'

gsap.registerPlugin(ScrollTrigger, ScrollSmoother)

const LoadingContext = createContext()

const LoadingProvider = ({ children }) => {
  const triggerRef = useRef()
  const smootherRef = useRef()
  const observerRef = useRef()
  const [loading, setLoading] = useState(true)
  const { mobile } = useMobileContext()
  const value = useMemo(() => {
    return {
      loading,
    }
  }, [loading])

  useEffect(() => {
    if (loading) return

    if (triggerRef.current) {
      triggerRef.current.kill()
    }
    if (smootherRef.current) {
      smootherRef.current.kill()
    }
    if (observerRef.current) {
      observerRef.current.kill()
    }

    triggerRef.current = ScrollTrigger.normalizeScroll(true)
    smootherRef.current = ScrollSmoother.create({
      smooth: 2.8,
      effects: true,
      smoothTouch: 0.5,
      speed: 0.7,
    })

    observerRef.current = Observer.create({
      target: window,
      type: 'wheel',
      onChangeY: self => {
        document.documentElement.scrollTop += self.deltaX * 20
      },
    })
    ScrollTrigger.refresh()
  }, [loading, mobile])

  return (
    <LoadingContext.Provider value={value}>
      <Loading loading={loading} setLoading={setLoading} />
      <div id="smooth-wrapper">
        <div id="smooth-content">{children}</div>
      </div>
    </LoadingContext.Provider>
  )
}

export const useLoadingContext = () => {
  return useContext(LoadingContext) || {}
}

export default LoadingProvider
