import { useMobileContext } from '@/MobileContext'
import React, { memo } from 'react'
import { styled } from 'styled-components'
import tinycolor from 'tinycolor2'

const darkenColor = color => {
  return tinycolor(color).darken(40).desaturate(60).toString()
}
const Wrapper = styled.div`
  background: ${({ color }) => color};
  width: ${({ size, mobile }) => (mobile ? size * 0.5 : size)}px;
  height: ${({ size, mobile }) => (mobile ? size * 0.5 : size)}px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  transform: ${({ rotate, visible, mobile }) =>
    `scale(${visible ? 1 : 0.3}) rotate(${rotate}deg) translateX(${
      mobile ? '15%' : 0
    }) translateY(${mobile ? '20%' : 0})`};
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;

  span {
    font-size: 22px;
    color: ${({ color }) => darkenColor(color)};
    font-weight: 400;

    @media (max-width: 1180px) {
      font-size: 12px;
    }
  }

  @media (max-width: 768px) {
    display: none;
  }
`

const Bubble = memo(({ word, visiblePercent, scrollProgress, ...props }) => {
  const visible = scrollProgress >= visiblePercent
  const { mobile } = useMobileContext()

  return (
    <Wrapper {...props} visible={visible} mobile={mobile}>
      <span>{word}</span>
    </Wrapper>
  )
})

export default Bubble
