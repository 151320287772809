import FlagEn from '@/Assets/icons/components/FlagEn'
import FlagFr from '@/Assets/icons/components/FlagFr'
import { useMobileContext } from '@/MobileContext'
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useCursorContext } from './Cursor/CursorContext'

const flags = {
  en: <FlagEn />,
  fr: <FlagFr />,
}

export const LanguageSwitch = () => {
  const { mobile } = useMobileContext()
  const { i18n } = useTranslation()
  const { setHideCursor } = useCursorContext()
  const [scale, setScale] = useState(1)

  const onChangeLang = useCallback(() => {
    i18n.changeLanguage(i18n.language === 'en' ? 'fr' : 'en')
  }, [i18n])

  return (
    <div
      style={{
        transition: 'transform 0.2s ease-in-out',
        transform: `scale(${!mobile ? scale : 1})`,
      }}
      onMouseEnter={() => {
        setScale(1.6)
        setHideCursor(true)
      }}
      onMouseLeave={() => {
        setScale(1)
        setHideCursor(false)
      }}
      onMouseMove={() => {
        setScale(1.6)
        setHideCursor(true)
      }}
      onClick={onChangeLang}
    >
      {flags[i18n.language]}
    </div>
  )
}
