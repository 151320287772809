import { useWindowSize } from '@/Hooks'
import { useMobileContext } from '@/MobileContext'
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from 'react'
import { useState } from 'react'
const HomeContext = createContext()

export const HomeProvider = ({ children, ...config }) => {
  const { mobile } = useMobileContext()
  const [scrollY, setScrollY] = useState(0)
  const carouselRef = useRef(null)
  const servicesRef = useRef(null)
  const howItWorksRef = useRef(null)
  const { height, width } = useWindowSize()
  const [homeStyles, setHomeStyles] = useState({
    scaleButton: 1,
    buttonAnimationFinish: false,
  })

  const rafRef = useRef(null)

  const updateScroll = useCallback(() => {
    setScrollY(window.scrollY)
    rafRef.current = requestAnimationFrame(updateScroll)
  }, [])

  const handleScroll = useCallback(() => {
    if (mobile) {
      return null
    }
    if (!rafRef.current) {
      rafRef.current = requestAnimationFrame(updateScroll)
    }
  }, [updateScroll, mobile])

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true })
    return () => {
      window.removeEventListener('scroll', handleScroll)
      if (rafRef.current) {
        cancelAnimationFrame(rafRef.current)
        rafRef.current = null
      }
    }
  }, [handleScroll])

  const value = useMemo(() => {
    return {
      ...config,
      windowHeight: height,
      windowWidth: width,
      homeStyles,
      setHomeStyles,
      carouselRef,
      servicesRef,
      howItWorksRef,
      scrollY,
    }
  }, [
    config,
    height,
    width,
    homeStyles,
    carouselRef,
    scrollY,
    servicesRef,
    howItWorksRef,
  ])

  if (!value || !value.windowHeight || !value.windowWidth) {
    return null
  }

  return <HomeContext.Provider value={value}>{children}</HomeContext.Provider>
}

export function useHomeContext() {
  return useContext(HomeContext) || {}
}
