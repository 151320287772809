import { useMobileContext } from '@/MobileContext'
import React, { useEffect, useRef } from 'react'
import { useSpring, animated } from 'react-spring'

const MobileApparition = ({ children, direction = 'translateY(40px)' }) => {
  const { mobile } = useMobileContext()
  const ref = useRef()

  const [props, set] = useSpring(() => ({
    opacity: 0,
    transform: direction,
    config: { duration: 800 },
  }))

  useEffect(() => {
    if (!mobile) return

    const currentRef = ref.current

    const observer = new IntersectionObserver(
      ([entry]) => {
        set({
          opacity: entry.isIntersecting ? 1 : 0,
          transform: entry.isIntersecting ? 'translateY(0px)' : direction,
        })
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 0.2,
      },
    )

    if (currentRef) {
      observer.observe(currentRef)
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef)
      }
    }
  }, [ref, set, mobile, direction])

  if (!mobile) return children

  return (
    <animated.div style={props} ref={ref}>
      {children}
    </animated.div>
  )
}

export default MobileApparition
