import React from 'react'

export const LabeledBloc = ({ label, children }) => {
  return (
    <div className="w-full flex flex-col gap-10">
      <h3 className="text-3xl font-medium max-md:text-lg">{label}</h3>
      {children}
    </div>
  )
}
