import themeGlobal, { darkColors, lightColors } from '@/Theme'
import { useMemo } from 'react'
import { useState } from 'react'

const useTheme = () => {
  const [themeMode, setThemeMode] = useState(themeGlobal.themeName) // Default to light theme
  const colors = useMemo(
    () => (themeMode === 'light' ? lightColors : darkColors),
    [themeMode],
  )

  const theme = {
    ...themeGlobal,
    colors: {
      ...colors,
    },
  }

  const toggleTheme = () => {
    setThemeMode(prevThemeMode =>
      prevThemeMode === 'light' ? 'dark' : 'light',
    )
  }

  return { theme, themeMode, toggleTheme }
}

export default useTheme
