import gsap from 'gsap'
import React, { memo, useEffect } from 'react'
import { useRef } from 'react'
import { Link, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import Arrow from '../Assets/icons/arrow.svg'
import ButtonPosition from './ButtonPosition'
import { Interactive } from './Cursor/Interactive'

const Container = styled.div`
  background: ${({ variant, theme }) =>
    variant === 'light'
      ? theme.colors.white
      : `linear-gradient(${theme.colors.grey3}, ${theme.colors.black})`};
  padding: 40px 20px;
  width: 100%;
  z-index: 1;

  @media (max-width: 1024px) {
    padding: 32px 16px;
  }

  @media (max-width: 768px) {
    padding: 24px 16px;
  }
`

const StyledBanner = styled.div`
  padding: 80px 100px;
  background: ${props => props.theme.colors.primary};
  border-radius: 90px;
  gap: 40px;
  height: calc(100vh - 80px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  h2 {
    font-weight: 600;
    line-height: 96px;
    text-align: center;
    font-size: 90px;
    max-width: 1240px;
    color: ${props => props.theme.colors.white};

    @media (max-width: 1280px) {
      font-size: 72px;
      line-height: 86.4px;
    }
  }

  a {
    padding: 40px;
    border-radius: 96px;
    background: ${props => props.theme.colors.white};
    font-weight: 500;
    font-size: 30px;
    line-height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }

  @media (max-width: 1024px) {
    padding: 64px 80px;
    height: calc(100vh - 64px);

    h2 {
      font-size: 64px;
      line-height: 76.8px;
    }

    a {
      font-size: 24px;
    }
  }

  @media (max-width: 768px) {
    padding: 48px 24px;
    height: calc(100vh - 48px);

    h2 {
      font-size: 40px;
      line-height: 48px;
    }

    a {
      font-size: 16px;
      padding: 24px;
    }

    img {
      width: 16px;
    }
  }

  @media (max-width: 640px) {
    border-radius: 48px;
    padding: 20px 16px;
    height: calc(65vh - 48px);

    h2 {
      font-size: 28px;
      line-height: 38.4px;
    }
  }
`

const Banner = memo(({ label, title, variant, href, to }) => {
  const Button = href ? 'a' : Link
  const containerRef = useRef()
  const bannerRef = useRef()
  const gsapRef = useRef()
  const { pathname } = useLocation()

  useEffect(() => {
    if (!containerRef.current || !bannerRef.current) return

    const containerElement = containerRef.current
    const bannerElement = bannerRef.current
    const matchMedia = gsap.matchMedia()
    matchMedia.add('(min-width: 1180px)', () => {
      gsapRef.current = gsap.context(() => {
        gsap.to(bannerElement, {
          scale: 1.06, // ajustez la valeur du zoom comme vous le souhaitez
          y: '+=11%',
          scrollTrigger: {
            trigger: containerElement,
            start: 'bottom 80%',
            end: 'bottom top',
            scrub: 2,
          },
        })
      })
    })

    return () => {
      if (gsapRef.current) {
        gsapRef.current.revert()
      }
    }
  }, [pathname])

  return (
    <Container ref={containerRef} variant={variant}>
      <StyledBanner ref={bannerRef}>
        <h2>{title}</h2>
        <ButtonPosition>
          <Interactive>
            <Button href={href} to={to}>
              {label} <img src={Arrow} alt="arrow" loading="lazy" />
            </Button>
          </Interactive>
        </ButtonPosition>
      </StyledBanner>
    </Container>
  )
})

export default Banner
