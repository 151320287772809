import React, { useMemo, useRef } from 'react'
import Slider from 'react-slick'
import leftArrow from '../../Assets/icons/left-arrow.svg'
import rightArrow from '../../Assets/icons/right-arrow.svg'
import LogoReview from '../../Assets/icons/logo-review.svg'
import styled from 'styled-components'
import { Interactive } from '../Cursor/Interactive'
import baptiste from '@/Assets/images/reviews/baptiste.webp'
import pierre from '@/Assets/images/reviews/pierre.webp'
import david from '@/Assets/images/reviews/david.webp'
import nicolas from '@/Assets/images/reviews/nicolas.webp'
import charles from '@/Assets/images/reviews/charles.webp'
import clsx from 'clsx'
import { useHomeContext } from '@/Containers/Home/HomeContext'
import { useTranslation } from 'react-i18next'

const StyledCard = styled.div`
  background: ${props => props.theme.colors.grey1 + '10'};
  border-radius: 70px;

  @media (max-width: 640px) {
    border-radius: 42px;
  }
`

const Card = ({ image, content, name, infos, logo }) => {
  return (
    <StyledCard
      className={clsx(
        'flex flex-col items-center justify-center gap-8 h-full px-32 py-12 mx-3 lg:px-20',
        'max-lg:px-12 max-lg:py-8',
        'max-sm:px-4 max-sm:py-4 max-sm:mx-1',
      )}
    >
      <img
        src={image}
        alt="customer"
        className="w-32 h-32 rounded-full"
        loading="lazy"
      />
      <div className="card flex flex-col items-center justify-center gap-6 h-fit">
        <p className="card min-h-[25vh]">{content}</p>
        <span className="text-white font-semibold text-center">
          {name} <span className="ml-1 opacity-60">{infos}</span>
        </span>
      </div>
    </StyledCard>
  )
}

const Carousel = () => {
  const { t } = useTranslation()
  const { windowWidth } = useHomeContext()
  const slider = useRef(null)
  const settings = {
    className: 'center',
    dots: false,
    arrows: false,
    speed: 500,
    adaptiveHeight: true,
    variableWidth: true,
    centerPadding: windowWidth <= 768 ? '20px' : '180px',
    centerMode: true,
  }

  const reviews = useMemo(
    () => [
      {
        image: baptiste,
        content: t('reviews.baptiste.content'),
        infos: t('reviews.baptiste.infos'),
        name: 'Baptiste Geffrault',
        logo: LogoReview,
      },
      {
        image: pierre,
        content: t('reviews.pierre.content'),
        infos: t('reviews.pierre.infos'),
        name: 'Pierre Duchesne',
        logo: LogoReview,
      },
      {
        image: david,
        content: t('reviews.david.content'),
        infos: t('reviews.david.infos'),
        name: 'David Roth',
        logo: LogoReview,
      },
      {
        image: nicolas,
        content: t('reviews.nicolas.content'),
        infos: t('reviews.nicolas.infos'),
        name: 'Nicolas Guillaume',
        logo: LogoReview,
      },
      {
        image: charles,
        content: t('reviews.charles.content'),
        infos: t('reviews.charles.infos'),
        name: 'Charles Lefebvre',
        logo: LogoReview,
      },
    ],
    [t],
  )

  return (
    <>
      <div className="flex flex-1 flex-col">
        <Slider ref={slider} {...settings}>
          {reviews.map((review, index) => (
            <Card key={index} {...review} />
          ))}
        </Slider>
      </div>
      <div className="w-full flex justify-center gap-16">
        <Interactive>
          <img
            src={leftArrow}
            onClick={() => slider.current?.slickPrev()}
            alt="arrow-left"
            loading="lazy"
          />
        </Interactive>
        <Interactive>
          <img
            src={rightArrow}
            onClick={() => slider.current?.slickNext()}
            alt="arrow-right"
            loading="lazy"
          />
        </Interactive>
      </div>
    </>
  )
}

export default Carousel
