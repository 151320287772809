import React from 'react'
import styled from 'styled-components'

const StyledInput = styled.input`
  width: 100%;
  border-bottom: 1px solid ${props => props.theme.colors.black};
  outline: none;
  padding: 24px 20px 24px 0px;
  color: ${props => props.theme.colors.black};
  opacity: 0.4;
  font-family: Satoshi Variable;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  &::placeholder {
    font-family: 'Satoshi Variable';
    color: ${props => props.theme.colors.black};
    opacity: 0.4;
    font-family: Satoshi Variable;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
  }

  &:focus {
    border-color: ${props => props.theme.colors.primary};
  }
`

const Input = ({ onChange, ...props }) => {
  return <StyledInput onChange={e => onChange(e.target.value)} {...props} />
}

export default Input
