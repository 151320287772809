import { useWindowSize } from '@/Hooks'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { useControlScrollContext } from '../ControlScrollProvider'
import { Interactive } from '../Cursor/Interactive'

const Solutions = () => {
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const { height, width } = useWindowSize()
  const columns = useMemo(
    () => [
      {
        label: t('header.nav_item_1'),
        top: height * 2.38,
      },
      {
        label: t('header.nav_item_2'),
        top: height * 15.36,
      },
      {
        label: t('header.nav_item_3'),
        top: height * 23.1,
      },
      {
        label: t('header.nav_item_4'),
        top: height * 26.6,
      },
    ],
    [height, t],
  )

  const firstPart = columns.slice(0, 2)
  const secondPart = columns.slice(2, 4)
  const { onScrollTo } = useControlScrollContext()

  if (width <= 1180 || pathname !== '/') {
    return <div />
  }

  return (
    <div className="flex gap-5">
      <ul className="flex flex-col gap-5">
        {firstPart.map(item => {
          return (
            <li className="list-none text-white text-lg	hover:opacity-60 cursor-pointer">
              <Interactive>
                <a
                  onClick={e => {
                    e.preventDefault()
                    onScrollTo(item.top)
                  }}
                  href="https://inprogress.agency/"
                  className="flex w-32 text-sm"
                >
                  {item.label}
                </a>
              </Interactive>
            </li>
          )
        })}
      </ul>
      <ul className="flex flex-col gap-5">
        {secondPart.map(item => {
          return (
            <li className="list-none text-white text-lg	hover:opacity-60 cursor-pointer">
              <Interactive>
                <a
                  onClick={e => {
                    e.preventDefault()
                    onScrollTo(item.top)
                  }}
                  href="https://inprogress.agency/"
                  className="flex w-32 text-sm"
                >
                  {item.label}
                </a>
              </Interactive>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default Solutions
