import * as React from 'react'
import { useTheme } from '@/Hooks'

function SvgArrowTopRight(props) {
  const theme = useTheme()

  return (
    <svg
      width={props.size || 24}
      height={props.size || 24}
      fill="none"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      {/* <G scale={(props.size || 24) / 25}> */}
      <path
        d="M1 18.3333L18.3333 1M18.3333 1V17.64M18.3333 1H1.69333"
        stroke={props.color || theme?.colors?.white || '#ffffff'}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      {/* </G> */}
    </svg>
  )
}

export default SvgArrowTopRight
