import React, { memo, useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import Slider from 'react-slick'
import clsx from 'clsx'

const Wrapper = styled.div`
  height: 30vh !important;
  aspect-ratio: ${props => props.aspectRatio} !important;
  border-radius: 20px !important;
  overflow: hidden;
  mix-blend-mode: initial !important;
  min-height: 220px;
  box-shadow: ${props =>
    props.isLoad ? '0px 10px 22px -5px rgba(78, 77, 77, 0.1)' : 'none'};

  * {
    height: 100%;
  }
`

export const ImagesCursor = memo(({ secondaryCursor, project, ...props }) => {
  const [isLoad, setIsLoad] = useState(false)
  const [projectActive, setProjectActive] = useState(project)
  const { images, aspectRatio } = projectActive || {}

  const settings = {
    fade: true,
    infinite: true,
    slidesToShow: 1,
    arrows: false,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 1200,
    draggable: false,
    pauseOnHover: false,
    adaptativeHeight: true,
    accessibility: false,
    centerPadding: 0,
  }

  const onLoad = useCallback(() => {
    if (isLoad) return

    setIsLoad(true)
  }, [setIsLoad, isLoad])

  useEffect(() => {
    if (projectActive?.title === project?.title) return

    // Reset loading state
    setIsLoad(false)

    // Clear and then set the active project
    setProjectActive(null)

    // Maybe add a small delay here if needed
    setTimeout(() => setProjectActive(project), 0)

    return () => {
      setIsLoad(false)
    }
  }, [project, projectActive, setIsLoad, setProjectActive])

  if (!projectActive) return null

  return (
    <Wrapper
      {...props}
      ref={secondaryCursor}
      isLoad={isLoad}
      aspectRatio={aspectRatio}
      className="secondary-cursor"
    >
      <Slider
        className={clsx(
          'h-full w-full opacity-0 transition-opacity duration-500 ease-in-out',
          isLoad && 'opacity-100',
        )}
        key={projectActive.title}
        {...settings}
      >
        {images.map((image, index) => (
          <img
            key={index}
            onLoad={onLoad}
            src={image}
            srcSet={image}
            alt=""
            loading="lazy"
            height="220px"
          />
        ))}
      </Slider>
    </Wrapper>
  )
})
