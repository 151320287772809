import React from 'react'
import { useCursorContext } from './CursorContext'

export const Interactive = props => {
  const { setScale } = useCursorContext()

  return (
    <div
      className="w-fit h-fit"
      onMouseEnter={() => setScale(3)}
      onMouseLeave={() => setScale(1)}
      onClick={() => setScale(1)}
      onMouseMove={() => setScale(3)}
      {...props}
    />
  )
}
