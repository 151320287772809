import { useHomeContext } from '@/Containers/Home/HomeContext'
import gsap from 'gsap'
import React, { memo, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import Bubble from './Bubble'

const useGetBubbles = () => {
  const { t } = useTranslation()
  return [
    {
      x: '10%',
      y: '30%',
      word: t('words.listen'),
      color: '#f7def9',
      size: 170,
      rotate: -10,
      visiblePercent: 0.14,
    },
    {
      x: '45%',
      y: '37%',
      word: t('words.create'),
      color: '#CCD0FF',
      size: 190,
      rotate: 5,
      visiblePercent: 0.68,
    },
    {
      x: '15%',
      y: '72%',
      word: t('words.deploy'),
      color: '#FFF8A5',
      size: 160,
      rotate: 10,
      visiblePercent: 0.82,
    },
  ]
}

const StyledPath = styled.div`
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 10;
  pointer-events: none;
  will-change: position, transform, top, left;
  position: absolute;
`

const Line = styled.path`
  stroke-dasharray: ${({ pathLength }) => `${pathLength} ${pathLength}`};
  stroke-dashoffset: ${({ scrollProgress, pathLength }) =>
    (1 - scrollProgress) * pathLength};
`

const HowItWorksPath = memo(({ progress, totalHeight }) => {
  const { windowHeight } = useHomeContext()
  const svgRef = useRef()
  const pathRef = useRef()
  const containerRef = useRef()
  const gsapRef = useRef()
  const [pathLength, setPathLength] = useState(0)
  const translateY = Math.round((totalHeight - windowHeight) / 2)

  useEffect(() => {
    if (pathRef?.current) {
      const length = pathRef?.current.getTotalLength()
      setPathLength(length)
    }
  }, [pathRef])

  useEffect(() => {
    const container = containerRef.current
    gsapRef.current = gsap.context(() => {
      const tl = gsap.timeline({
        repeat: -1,
        yoyo: true,
        defaults: {
          duration: 4,
          ease: 'power1.inOut',
        },
      })
      gsap.set(container, { y: `-=${translateY}` })

      tl.fromTo(
        container,
        { scaleX: 1, transformOrigin: '50% 50%' },
        { scaleX: 1.04 },
      ).fromTo(
        container,
        { scaleX: 1.04, transformOrigin: '50% 50%' },
        { scaleX: 0.99 },
      )
    })

    return () => {
      if (gsapRef.current) {
        gsapRef.current.revert()
      }
    }
  }, [translateY])

  const bubblePositions = useGetBubbles()

  return (
    <StyledPath
      ref={containerRef}
      className={pathLength && progress ? 'opacity-100' : 'opacity-0'}
    >
      {bubblePositions.map((bubble, index) => {
        return (
          <Bubble
            scrollProgress={progress}
            key={index}
            style={{ left: bubble.x, top: bubble.y }}
            {...bubble}
          />
        )
      })}
      <svg
        ref={svgRef}
        width="100%"
        height={`${(totalHeight / windowHeight) * 100 || 0}%`}
        viewBox={`0 0 100 ${(totalHeight / windowHeight) * 100 || 0}`}
        fill="none"
        preserveAspectRatio="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <Line
          ref={pathRef}
          pathLength={pathLength}
          scrollProgress={progress}
          d="M0 0.087 C5.759 2.914, 7.586 4.531, 10.868 21.949 C14.726 50.636, 23.879 84.117, 50.064 84.117 C82.544 84.117, 71.503 38.847, 51.697 43.719 C32.041 48.673, 24.589 83.886, 0 97"
          stroke="white"
          strokeWidth="0.2"
        />
      </svg>
    </StyledPath>
  )
})

export default HowItWorksPath
