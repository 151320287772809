import React, { useEffect } from 'react'
import { Router } from './Router'
import { ThemeProvider } from 'styled-components'
import moment from 'moment'
import 'moment/locale/fr'
import 'moment/locale/es'
import 'moment/locale/ar'
import { useTranslation } from 'react-i18next'
import { useTheme } from './Hooks'
import './global.css'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { CursorProvider } from '@/Components'
import ControlScrollProvider from './Components/ControlScrollProvider'
import MobileProvider from './MobileContext'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import LoadingProvider from './Containers/Loading/LoadingContext'
import { Awwwards } from './Components/Awwwards'

const App = () => {
  const { i18n } = useTranslation()
  const { theme } = useTheme()
  useEffect(() => {
    moment.locale(i18n.language)
  }, [i18n])

  return (
    <MobileProvider>
      <ThemeProvider theme={theme}>
        <CursorProvider>
          <ControlScrollProvider>
            <LoadingProvider>
              <Router />
            </LoadingProvider>
            <ToastContainer theme="light" position="top-center" limit={3} />
            <Awwwards />
          </ControlScrollProvider>
        </CursorProvider>
      </ThemeProvider>
    </MobileProvider>
  )
}

export default App
