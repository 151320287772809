import React, { memo, useCallback, useEffect, useState } from 'react'
import { styled } from 'styled-components'

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  flex: 1;
  height: 100vh;
  width: 100vw;
  z-index: 999999;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  pointer-events: none;
`
const Wall = styled.div`
  background: ${props => props.theme.colors.black};
  height: 50%;
  width: 100%;
  left: 0;
  position: absolute;
  transform: ${props =>
    props.isTop
      ? props.loading
        ? 'translateY(0)'
        : 'translateY(-100%)'
      : props.loading
      ? 'translateY(0)'
      : 'translateY(100%)'};
  transition: transform 0.3s ease-out 0.6s;
`
const Loader = styled.div`
  width: ${props => (!props.loading ? '100%' : '70%')};
  max-width: ${props => (!props.loading ? '100%' : '800px')};
  height: 2px;
  background: ${props => props.theme.colors.grey2};
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  transition: width 0.3s ease-out, opacity 0s ease-out 0.6s;
  opacity: ${props => (props.loading ? 1 : 0)};
`

const Slider = styled.div`
  height: 3px;
  background: ${props => props.theme.colors.white};
  z-index: 1;
`

const Percentage = styled.span`
  font-size: 22px;
  color: ${props => props.theme.colors.white};
  font-weight: 300;
  top: 28px;
  position: absolute;
`

const Title = styled.span`
  font-size: 20px;
  color: ${props => props.theme.colors.white};
  font-weight: 200;
  bottom: 40px;
  position: absolute;

  &:after {
    content: '...';
    position: absolute;
  }
`

const LIVECHAT_TOKEN = process.env.REACT_APP__LIVECHAT_TOKEN

const Loading = memo(({ loading, setLoading }) => {
  const [percentage, setPercentage] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      setPercentage(prev => {
        if (prev < 100) {
          return prev + 2
        } else {
          setTimeout(() => {
            setLoading(false)
          }, 400)
          clearInterval(interval)
        }
        return prev
      })
    }, 30)

    return () => {
      clearInterval(interval)
    }
  }, [setLoading])

  useEffect(() => {
    if (loading) return

    window.$crisp = []
    if (LIVECHAT_TOKEN) {
      window.CRISP_WEBSITE_ID = LIVECHAT_TOKEN
      ;(function () {
        var d = document
        var s = d.createElement('script')

        s.src = 'https://client.crisp.chat/l.js'
        s.async = 1
        d.getElementsByTagName('head')[0].appendChild(s)
      })()
    } else {
      window.CRISP_WEBSITE_ID = ''
    }
  }, [loading])

  const preventScroll = useCallback(e => {
    e.preventDefault()
  }, [])

  useEffect(() => {
    if (loading) {
      window.addEventListener('wheel', preventScroll, {
        passive: false,
      })
      window.addEventListener('touchmove', preventScroll, {
        passive: false,
      })
    } else {
      window.removeEventListener('wheel', preventScroll)
      window.removeEventListener('touchmove', preventScroll)
    }

    if (!loading) {
      window.removeEventListener('wheel', preventScroll)
      window.removeEventListener('touchmove', preventScroll)
    }
  }, [loading, preventScroll])

  return (
    <Container>
      <Wall className="top-0" loading={loading} isTop={true} />
      <Loader loading={loading}>
        <img
          className="h-16 w-16 absolute bottom-[120px]"
          src="/logo.png"
          alt="Icon"
          loading="lazy"
        />
        <Title>INPROGRESS AGENCY</Title>
        <Slider style={{ width: `${percentage}%` }} />
        <Percentage>{percentage}%</Percentage>
      </Loader>
      <Wall className="bottom-0" loading={loading} isTop={false} />
    </Container>
  )
})

export default Loading
