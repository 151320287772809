import { styled } from 'styled-components'

export const StyledFooter = styled.footer`
  width: 100vw;
  background: #000;
  padding: 208px 96px 112px;
  display: flex;
  justify-content: center;

  @media (max-width: 1180px) {
    padding: 208px 64px 112px;
  }

  @media (max-width: 1024px) {
    padding: 208px 48px 112px;
  }
  @media (max-width: 768px) {
    padding: 160px 24px 92px;

    .container {
      height: auto !important;
      flex-direction: column;
    }
    .right-navbar {
      padding-top: 0px !important;
      height: 72px !important;
    }
    .left-navbar {
      gap: 120px;
    }
  }

  @media (max-width: 640px) {
    padding: 160px 16px 92px;
  }

  .container {
    flex: 1;
    max-width: 1200px;
    height: 292px;
    display: flex;
    justify-content: space-between;
  }

  .left-navbar {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    span {
      color: ${({ theme }) => theme.colors.white};
      font-size: 28px;
      font-weight: 600;
    }
  }

  .right-navbar {
    padding-top: 104px;
    height: 292px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`
