import React, { useCallback, useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useGetFieldBlocs } from './fieldBlocs'
import { LabeledBloc } from './LabeledBloc'
import { Interactive } from '../Cursor/Interactive'
import { styled } from 'styled-components'
import emailjs from '@emailjs/browser'
import { toast } from 'react-toastify'
import ReCAPTCHA from 'react-google-recaptcha'

const Button = styled.button`
  padding: 40px 60px;
  border-radius: 96px;
  background: ${props => props.theme.colors.black};
  color: ${props => props.theme.colors.white};
  font-weight: 500;
  font-size: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;

  &:disabled,
  &[disabled] {
    opacity: 0.5;
  }

  @media (max-width: 768px) {
    padding: 20px 30px;
    font-size: 18px;
  }
`

const initialValues = {
  name: '',
  categories: [],
  budgets: [],
  email: '',
  phone: '',
  content: '',
}

const ContactForm = () => {
  const fieldBlocs = useGetFieldBlocs()
  const recaptchaRef = useRef()
  const { t } = useTranslation()

  const [values, setValues] = useState(initialValues)
  const [loading, setLoading] = useState(false)

  const handleSubmit = useCallback(
    async e => {
      if (loading) return

      setLoading(true)
      e.preventDefault()
      try {
        await recaptchaRef.current.executeAsync()
        await emailjs.send(
          'service_fd6s4j9',
          'template_f70dvdt',
          values,
          'Pz2Ndh92lTxQ2btzh',
        )
        toast.success(t('contact.toast'))
        setValues(initialValues)
      } catch (err) {
        console.log(err)
        toast.error(err.text)
      }
      setLoading(false)
    },
    [values, loading, setLoading, setValues, recaptchaRef, t],
  )

  const handleChange = useCallback(
    (value, name) => {
      setValues({ ...values, [name]: value })
    },
    [values, setValues],
  )

  return (
    <form
      onSubmit={handleSubmit}
      className="flex flex-col w-full gap-16 mb-32 max-w-[1240px] max-md:gap-10 max-md:items-center"
    >
      {fieldBlocs.map(({ title, fields }, index) => (
        <LabeledBloc key={index} label={title}>
          {fields.map(({ component, name, ...props }) => {
            const Component = component
            return (
              <Component
                onChange={value => handleChange(value, name)}
                value={values[name]}
                {...props}
              />
            )
          })}
        </LabeledBloc>
      ))}
      <ReCAPTCHA
        ref={recaptchaRef}
        size="invisible"
        sitekey={process.env.REACT_APP__RECAPTCHA_SITE_KEY}
        badge="bottomleft"
      />
      <Interactive>
        <Button disabled={loading}>{t('contact.send_projects')}</Button>
      </Interactive>
    </form>
  )
}

export default ContactForm
